import { Brands } from './brands';

const runningEnv = process.env.ENV;

export const EL_PRICE_PARAM_ID = '-32002';

export const phone = {
  regex: /^(\+)?[\d\-)(]+$/,
  minLength: 5,
  maxLength: 25,
};

export const address = {
  postalCode: {
    regex: /^[a-zA-Z\d\- ]+$/,
    maxLength: 20,
  },
  cityMaxLength: 100,
  regionMaxLength: 100,
  pattern: /^[\p{L}\p{Nd}'"\-–.,#&/() ]+$/u,
};

export const servicePartner = {
  name: {
    pattern: /^[\p{L}\p{Nd}'\-. ]+$/u,
    maxLength: 50,
  },
};

export const emailAddress = {
  maxLength: 255,
  antiXssRegex: /^[^%<>^$'`*|{}="]+$/,
};

export const timeZoneOffsetRegex = /\(([^)]+)\)/;
export const userNamePattern = /^[\p{L}\p{Nd}'\-. ]+$/u;
export const parameterInvalidValue = -32760;
export const defaultMaxLength = 255;
export const passwordMaxLength = 128;
export const dateFormatForVaction = 'YYYY/MM/DD';
export const groupNameRegex = /^[\p{L}\p{M}\p{N}0-9 !#%&'()*\-._,+/@:;=?[\]~¥©®🠉\p{Emoji}]+$/gu;

// TODO: Review with business
export const BRANDS_WITH_WEBSHOP_ACTIVE = ['NIBE'];

export const BRAND_APPROVAL_STATUSES = {
  APPROVED: 'Approved',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
};

// TODO: Move to app config
export const DEFAULT_LANGUAGE = 'en';

export const APP_STATUS_IDLE = 'status-0';

export const CLOUD_STATUS_CHECK_INTERVAL = 5 * 60 * 1000;

export const AUTH_REQUEST_HEADER_SCOPE_DEFAULT = 'READSYSTEM REGISTRATION WRITESYSTEM';
export const AUTH_ACCESS_TOKEN = 'AUTH_ACCESS_TOKEN';
export const CONSUMER_TOKEN = 'CONSUMER_TOKEN';
export const OFFLINE_ACCESS = 'offline_access';

export const BRANDS_WITH_FREE_SERVICES = ['CTC'];

export const HIDE_GROUPING_BUTTON_FROM_BRANDS = ['NIBE', 'NIBEF'];

export enum SpEntityType {
  Group = 'spGroup',
  System = 'group',
}

export const isTopLevelGroup = {
  Yes: '1',
  No: '0',
};

// TODO: Move to UI config
export const DialogModes = {
  Default: 'default',
  Create: 'create',
  Edit: 'edit',
  Delete: 'delete',
};

export const spinnerDarkColor = '#414042';

export const KEYBOARD_KEYS = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  SPACE: 'Space',
};

export const noRegion = {
  name: 'None',
  code: 'NONE',
};

export const VOUCHER_ELIGIBLE_BRANDS = [Brands.NIBE, runningEnv === 'production' ? '' : Brands.CLIMATEMASTER].filter(
  Boolean,
);

export const PROD_REGISTRATION_BRANDS = [Brands.NIBE, Brands.NIBEF];
export const PROD_REGISTRATION_COUNTRIES = ['Germany'];
