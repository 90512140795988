import { Brands } from '@/config/brands';
import { KEYBOARD_KEYS } from '@/config/common';

export function a11yKeyPress(handler) {
  return function onKeyPress(event) {
    const code = event.code;
    if (code === KEYBOARD_KEYS.ENTER || KEYBOARD_KEYS.SPACE) {
      return handler(event);
    }
  };
}

export const sortArrayByLocaleCompare = (array, key, order) => {
  const sortedArray = array.slice().sort((a, b) => {
    return a[key].localeCompare(b[key]);
  });
  order === 'desc' && sortedArray.reverse();
  return sortedArray;
};

const defaultInconsistentBrandsList = [
  Brands.NIBE,
  Brands.NIBEF,
  Brands.DANDELION,
  Brands.ENERTECH,
  Brands.TIKI,
  Brands.TEMPEFF,
  Brands.CLIMATEMASTER,
  Brands.AIRSITE,
  Brands.BIAWAR,
  Brands.MYUPLINK,
];

export const getColorForInconsistentBrands = (theme, inconsistentBrandsList = defaultInconsistentBrandsList) => {
  return inconsistentBrandsList.includes(theme.name) ? theme.COLORS.secondary : theme.COLORS.primary;
};

export const rgbaToHex = rgb =>
  `#${rgb
    .match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
    .slice(1)
    .map(n => parseInt(n, 10).toString(16).padStart(2, '0'))
    .join('')}`;
