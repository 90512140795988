import PropTypes from 'prop-types';
import React, { Suspense, createContext, useCallback, useMemo, useState } from 'react';

import Loader from '@jpi-cloud-web-pro/jpi-cloud-shared/components/Loader';

// export type FallbackType = NonNullable<React.ReactNode> | null;

// export interface FallbackContextType {
//   updateFallback: (fallback: FallbackType) => void;
// }

export const FallbackContext = createContext({
  // eslint-disable-next-line no-unused-vars
  updateFallback: context => {},
});

// interface FabllbackProviderProps {}

function FallbackProvider({ children }) {
  // TODO: Replace with spinner
  const [fallback, setFallback] = useState(() => <Loader dark isPage />);

  const updateFallback = useCallback(previousPage => {
    setFallback(() => previousPage);
  }, []);

  const renderChildren = useMemo(() => <>{children}</>, [children]);

  return (
    <FallbackContext.Provider value={{ updateFallback }}>
      <Suspense fallback={fallback}>{renderChildren}</Suspense>
    </FallbackContext.Provider>
  );
}

FallbackProvider.propTypes = {
  children: PropTypes.node,
};

export default FallbackProvider;
