import * as api from '@/api';

import {
  getLastUsedServicePartner,
  removeLastUsedServicePartner,
  setLastUsedServicePartner,
} from '@/utils/localStorage/servicePartner';
import { logoutUser } from '../Login/actions';
import { splitVatNumber } from './utils';

export const GET_PRO_ACCOUNT_REQUEST = 'editServicePartner/GET_PRO_ACCOUNT_REQUEST';
export const GET_PRO_ACCOUNT_RESPONSE = 'editServicePartner/GET_PRO_ACCOUNT_RESPONSE';
export const GET_PRO_ACCOUNT_ERROR = 'editServicePartner/GET_PRO_ACCOUNT_ERROR';

export const getProAccountInfo = spId => async dispatch => {
  try {
    dispatch({ type: GET_PRO_ACCOUNT_REQUEST });
    const response = await api.getProAccountInfo(spId);
    if (response.status === 200) {
      const servicePartner = splitVatNumber(response.data);

      dispatch({ type: GET_PRO_ACCOUNT_RESPONSE, servicePartner });
    }
  } catch (error) {
    dispatch({ type: GET_PRO_ACCOUNT_ERROR });
  }
};

export const EDIT_PRO_ACCOUNT_REQUEST = 'editServicePartner/EDIT_PRO_ACCOUNT_REQUEST';
export const EDIT_PRO_ACCOUNT_RESPONSE = 'editServicePartner/EDIT_PRO_ACCOUNT_RESPONSE';
export const EDIT_PRO_ACCOUNT_ERROR = 'editServicePartner/EDIT_PRO_ACCOUNT_ERROR';

// FIXME: Refactor this method and optimise usage of storage!!!
export const editProAccountInfo = (spId, payload) => async dispatch => {
  dispatch({ type: EDIT_PRO_ACCOUNT_REQUEST });
  try {
    const vatPrefix = payload.vatPrefix || '';
    const servicePartnerToEdit = {
      ...payload,
      vatNumber: vatPrefix + payload.vatNumber,
    };
    const editResponse = await api.editProAccountInfo(servicePartnerToEdit);
    const renamedServicepartner = { ...getLastUsedServicePartner(), servicePartnerName: payload.name };

    if (editResponse.status === 204) {
      const response = await api.getProAccountInfo(spId);

      setLastUsedServicePartner(renamedServicepartner);

      if (response.status === 200) {
        const servicePartner = splitVatNumber(response.data);
        dispatch({ type: EDIT_PRO_ACCOUNT_RESPONSE, servicePartner });
      } else {
        dispatch({ type: EDIT_PRO_ACCOUNT_ERROR });
        throw new Error(`Failed to retrieve updated service partner information with status ${response.status}`);
      }
      return;
    }
    dispatch({ type: EDIT_PRO_ACCOUNT_ERROR });
    throw new Error(`Failed to update service partner with status ${editResponse.status}`);
  } catch (e) {
    dispatch({ type: EDIT_PRO_ACCOUNT_ERROR });
    throw e;
  }
};

export const DELETE_ACCOUNT_REQUEST = 'profile/DELETE_ACCOUNT';
export const DELETE_ACCOUNT_RESPONSE_SUCCEDED = 'profile/DELETE_ACCOUNT_RESPONSE_SUCCEDED';
export const DELETE_ACCOUNT_FAILED = 'profile/DELETE_ACCOUNT_FAILED';

export const deleteServicePartnerAccount = spId => async dispatch => {
  dispatch({ type: DELETE_ACCOUNT_REQUEST });
  try {
    await api.deleteLastProAccount(spId);
    dispatch({ type: DELETE_ACCOUNT_RESPONSE_SUCCEDED });

    removeLastUsedServicePartner();
    dispatch(logoutUser(false));
    return true;
  } catch (e) {
    dispatch({ type: DELETE_ACCOUNT_FAILED, error: 'password' });
  }

  return false;
};

export const leaveProAccount = () => async dispatch => {
  dispatch({ type: DELETE_ACCOUNT_REQUEST });
  try {
    await api.leaveProAccount();
    dispatch({ type: DELETE_ACCOUNT_RESPONSE_SUCCEDED });

    removeLastUsedServicePartner();
    dispatch(logoutUser(false));
    return true;
  } catch (e) {
    dispatch({ type: DELETE_ACCOUNT_FAILED, error: 'password' });
  }

  return false;
};
export const RESET_DELETE_ERROR = 'profile/RESET_DELETE_ERROR ';
export const resetDeleteError = () => dispatch => dispatch({ type: RESET_DELETE_ERROR });
