import { fas } from '@jpi-cloud-web-pro/jpi-cloud-shared/styles/fas';
import { fontFaces } from '@jpi-cloud-web-pro/jpi-cloud-shared/styles/fonts';
import { createGlobalStyle } from 'styled-components';

const styled = { createGlobalStyle };

const GlobalStyle = styled.createGlobalStyle`
  ${fontFaces}
  ${fas}
`;

export default GlobalStyle;
