import React from 'react';
import { NotificationManager } from 'react-notifications';

import * as api from '@/api';
import events, { eventEmitter } from '@/events';
import { getAuthTokens } from '@/utils/localStorage/authentication';
import { FormattedMessage } from 'react-intl';

export const GET_INVITATIONS_REQUEST = 'invitations/GET_INVITATIONS_REQUEST';
export const GET_INVITATIONS_RESPONSE = 'invitations/GET_INVITATIONS_RESPONSE';
export const GET_INVITATIONS_ERROR = 'invitations/GET_INVITATIONS_ERROR';

export const getProAccountInvitations = spId => async dispatch => {
  try {
    dispatch({ type: GET_INVITATIONS_REQUEST });

    const accountInfo = await api.getProAccountInfo(parseInt(spId));
    const accountInvitations = await api.getProAccountInvitations();
    if (accountInvitations.status === 200) {
      dispatch({ type: GET_INVITATIONS_RESPONSE, invitations: accountInvitations.data, selectedSP: accountInfo.data });
    }
  } catch (error) {
    dispatch({ type: GET_INVITATIONS_ERROR });
  }
};

export const DELETE_INVITATION_REQUEST = 'invitations/DELETE_INVITATION_REQUEST';
export const DELETE_INVITATION_RESPONSE = 'invitations/DELETE_INVITATION_RESPONSE';

export const deleteProAccountInvitation = (group, invitation) => async dispatch => {
  dispatch({ type: DELETE_INVITATION_REQUEST });

  const token = getAuthTokens();
  const response = await api.deleteProAccountInvitation(invitation, token.access_token);
  if (response.status === 200) {
    dispatch({ type: DELETE_INVITATION_RESPONSE, invitation });
  }
};

export const RESEND_INVITATION_REQUEST = 'invitations/RESEND_INVITATION_REQUEST';
export const RESEND_INVITATION_RESPONSE = 'invitations/RESEND_INVITATION_RESPONSE';
export const RESEND_INVITATION_ERROR = 'invitations/RESEND_INVITATION_ERROR';

const UserStatusAtSystem = {
  NonRegistered: 0,
  Registered: 1,
};

export const resendProAccountInvitation = invitation => async dispatch => {
  dispatch({ type: RESEND_INVITATION_REQUEST });
  try {
    const token = getAuthTokens();
    const response = await api.resendProAccountInvitation(invitation, token.access_token);
    if (response.status === 200) {
      if (response.data === UserStatusAtSystem.NonRegistered)
        NotificationManager.success(
          <FormattedMessage
            id="add-invitation.error.request.email-not-found"
            defaultMessage="This user does not exist. We have sent an email for registration. After registration, please re-send an invitation to request access to the system"
          />,
          null,
          10000,
        );
      dispatch({ type: RESEND_INVITATION_RESPONSE });
    }
  } catch (e) {
    NotificationManager.error(
      <FormattedMessage
        id="invitation.somethingWentWrong"
        defaultMessage="Something went wrong! Please try again later."
      />,
    );
    dispatch({ type: RESEND_INVITATION_ERROR });
    eventEmitter.emit(events.appInsights.logError, e);
  }
};
