import { css } from 'styled-components';

export const fas = css`
  .fa,
  .fas,
  .far {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .far {
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
  }

  .fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
  .fa-stack {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2.5em;
  }
  .fa-stack-1x,
  .fa-stack-2x {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .fa-stack-1x {
    line-height: inherit;
  }
  .fa-stack-2x {
    font-size: 2em;
  }

  .fa-globe:before {
    content: '\\f0ac';
  }

  .fa-check-circle:before {
    content: '\\f058';
  }
  .fa-check:before {
    content: '\\f00c';
  }
  .fa-circle:before {
    content: '\\f111';
  }
  .fa-exclamation:before {
    content: '\\f12a';
  }
  .fa-times:before {
    content: '\\f00d';
  }
  .fa-info-circle:before {
    content: '\\f05a';
  }
  .fa-mail-bulk:before {
    content: '\\f674';
  }
  .fa-exclamation-triangle:before {
    content: '\\f071';
  }
  .fa-unlink:before {
    content: '\\f127';
  }
  .fa-arrow-alt-circle-up:before {
    content: '\\f35b';
  }
  .fa-arrow-alt-circle-down:before {
    content: '\\f358';
  }
  .fa-question-circle:before {
    content: '\\f059';
  }
  .fa-phone:before {
    content: '\\f095';
  }
  .fa-phone-square:before {
    content: '\\f098';
  }
  .fa-envelope-o:before {
    content: '\\f003';
  }
  .fa-copy:before {
    content: '\\f0ea';
  }
  .fa-battery-empty:before {
    content: '\\f244';
  }
  .fa-battery-half:before {
    content: '\\f242';
  }
  .fa-battery-full:before {
    content: '\\f240';
  }
  .fa-download:before {
    content: '\\f019';
  }
  .fa-info:before {
    content: '\\f129';
  }
  .fa-cog:before {
    content: '\\f013';
  }
  .fa-cogs:before {
    content: '\\f085';
  }
  .fa-chevron-up:before {
    content: '\\f077';
  }
  .fa-chevron-left:before {
    content: '\\f053';
  }
  .fa-chevron-right:before {
    content: '\\f054';
  }
  .fa-chevron-down:before {
    content: '\\f078';
  }
  .fa-plus:before {
    content: '\\f067';
  }
  .fa-minus:before {
    content: '\\f068';
  }
  .fa-share:before {
    content: '\\f064';
  }
  .fa-user-times:before {
    content: '\\f235';
  }
  .fa-icon-remove:before {
    content: '\\f00d';
  }
  .fa-bars:before {
    content: '\\f0c9';
  }
  .fa-sign-in:before {
    content: '\\f090';
  }
  .fa-caret-right:before {
    content: '\\f0da';
  }
  .fa-caret-down:before {
    content: '\\f0d7';
  }
  .fa-bell:before {
    content: '\\f0f3';
  }
  .fa-envelope:before {
    content: '\\f003';
  }
`;
