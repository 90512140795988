import {
  DELETE_ACCOUNT_FAILED,
  DELETE_ACCOUNT_REQUEST,
  EDIT_PRO_ACCOUNT_ERROR,
  EDIT_PRO_ACCOUNT_REQUEST,
  EDIT_PRO_ACCOUNT_RESPONSE,
  GET_PRO_ACCOUNT_ERROR,
  GET_PRO_ACCOUNT_REQUEST,
  GET_PRO_ACCOUNT_RESPONSE,
  RESET_DELETE_ERROR,
} from './actions';

import { LOGOUT_USER } from '../Login/actions';

const initialState = {
  loaded: false,
  loading: false,
  servicePartner: null,
  saved: null,
  deleteError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRO_ACCOUNT_REQUEST:
      return { ...state, loading: true, saved: null };
    case GET_PRO_ACCOUNT_RESPONSE:
      return { ...state, servicePartner: action.servicePartner, loading: false, loaded: true };
    case GET_PRO_ACCOUNT_ERROR:
      return { ...state, loading: false, saved: null };
    case EDIT_PRO_ACCOUNT_REQUEST:
      return { ...state, saved: null };
    case EDIT_PRO_ACCOUNT_RESPONSE:
      return { ...state, saved: true, servicePartner: action.servicePartner };
    case EDIT_PRO_ACCOUNT_ERROR:
      return { ...state, saved: false };
    case LOGOUT_USER:
      return { ...state, loaded: false, loading: false, servicePartner: null, saved: null };
    case DELETE_ACCOUNT_REQUEST:
      return { ...state, deleteError: null };
    case RESET_DELETE_ERROR:
      return { ...state, deleteError: null };
    case DELETE_ACCOUNT_FAILED:
      return { ...state, deleteError: action.error };
    default:
      return state;
  }
};
