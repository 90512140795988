import * as api from '@/api';
import { logoutUser } from '../Login/actions';

export const DELETE_ACCOUNT_REQUEST = 'profile/DELETE_ACCOUNT';
export const DELETE_ACCOUNT_FAILED = 'profile/DELETE_ACCOUNT_FAILED';

export const deleteAccount = (userId, password) => async dispatch => {
  dispatch({ type: DELETE_ACCOUNT_REQUEST });
  try {
    await api.deleteUserAccount(userId, password);
    dispatch(logoutUser(false));
    return true;
  } catch (e) {
    dispatch({ type: DELETE_ACCOUNT_FAILED, error: 'password' });
  }

  return false;
};

export const VALIDATE_CREDENTIALS_REQUEST = 'profile/VALIDATE_CREDENTIALS_REQUEST';
export const VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED = 'profile/VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED';
export const VALIDATE_CREDENTIALS_RESPONSE_FAILED = 'profile/VALIDATE_CREDENTIALS_RESPONSE_FAILED';
export const validateUserCredentials = (username, password) => async dispatch => {
  dispatch({ type: VALIDATE_CREDENTIALS_REQUEST });
  try {
    const response = await api.validateUserCredentials(username, password);
    if (response.status === 200) {
      dispatch({ type: VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED });
      return true;
    }
  } catch (e) {
    if (e.response && e.response.status === 400) {
      // User is already logged in, so we don't expect errors like 'User does not exist', or 'Email is not confirmed'
      dispatch({ type: VALIDATE_CREDENTIALS_RESPONSE_FAILED, error: 'password.incorrect' });
      return false;
    }
  }

  dispatch({ type: VALIDATE_CREDENTIALS_RESPONSE_FAILED, error: 'unknown' });
  return false;
};

export const RESET_DELETE_ERROR = 'profile/RESET_DELETE_ERROR ';
export const resetDeleteError = () => dispatch => dispatch({ type: RESET_DELETE_ERROR });

export const RESET_VALIDATE_CREDENTIALS_ERROR = 'profile/RESET_VALIDATE_CREDENTIALS_ERROR ';
export const resetValidateCredentialsError = () => dispatch => dispatch({ type: RESET_VALIDATE_CREDENTIALS_ERROR });

export const RESET_EMAIL_FORM_ERROR_REQUEST = 'profile/RESET_EMAIL_FORM_ERROR_REQUEST';
export const resetEmailError = () => dispatch => dispatch({ type: RESET_EMAIL_FORM_ERROR_REQUEST });
