import { css } from 'styled-components';

export const fontFaces = css`
  /* Regular */
  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  /* Italic */
  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
  }

  /* Medium */
  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  /* Medium Italic */
  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
  }

  /* Bold */
  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
  }

  /* Bold Italic */
  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-BoldItalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
  }

  /* Light */
  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  /* Light Italic */
  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
  }

  /* Thin */
  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
  }

  /* Thin Italic */
  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
  }

  /* Black */
  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
  }

  /* Black Italic */
  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-BlackItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'Berling W03_n4';
    src: url('./assets/fonts/b9ef90b2-310b-4ee3-89e0-81e45c784b42.eot?#iefix') format('embedded-opentype');
  }
  @font-face {
    font-family: 'Berling W03';
    src: url('./assets/fonts/b9ef90b2-310b-4ee3-89e0-81e45c784b42.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/1533ea8d-ffc0-4e25-860f-910034e2499c.woff2') format('woff2'),
      url('./assets/fonts/d3b27bce-23ad-4e0f-aacb-8605932770f5.woff') format('woff'),
      url('./assets/fonts/5249be6c-23c9-4e97-b285-9051c5a4d068.ttf') format('truetype'),
      url('./assets/fonts/6c449aeb-0df3-4077-9e5b-cdf2babe35a6.svg#6c449aeb-0df3-4077-9e5b-cdf2babe35a6') format('svg');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Frutiger W02_n4';
    src: url('./assets/fonts/c94bd41e-23b3-4522-8237-0a0f7bd0e953.eot?#iefix') format('embedded-opentype');
  }
  @font-face {
    font-family: 'Frutiger W02';
    src: url('./assets/fonts/c94bd41e-23b3-4522-8237-0a0f7bd0e953.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/361c8371-8c18-4a04-9430-ef7cd4bf06ec.woff2') format('woff2'),
      url('./assets/fonts/409b4bec-c67e-4764-a141-054db8df81d2.woff') format('woff'),
      url('./assets/fonts/efe9def0-77d1-4c28-8fd2-371236a3c8ed.ttf') format('truetype'),
      url('./assets/fonts/70b07d32-76f5-474e-83b0-7d5a3fefb15b.svg#70b07d32-76f5-474e-83b0-7d5a3fefb15b') format('svg');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Frutiger W02_i4';
    src: url('./assets/fonts/b535a427-c56c-4484-b834-dde05736736c.eot?#iefix') format('embedded-opentype');
  }
  @font-face {
    font-family: 'Frutiger W02';
    src: url('./assets/fonts/b535a427-c56c-4484-b834-dde05736736c.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/c4b86f74-d12f-4601-bd3b-f34829f87b09.woff2') format('woff2'),
      url('./assets/fonts/97935525-8e9d-4d37-a4df-956f6656209b.woff') format('woff'),
      url('./assets/fonts/1b127be3-961c-4b75-8ab4-7bec69e88c2f.ttf') format('truetype'),
      url('./assets/fonts/3048f5a2-9f13-481a-b01c-22228223c00b.svg#3048f5a2-9f13-481a-b01c-22228223c00b') format('svg');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'Frutiger W02_n7';
    src: url('./assets/fonts/4d38d09a-3017-4733-a624-366fb9a71388.eot?#iefix') format('embedded-opentype');
  }
  @font-face {
    font-family: 'Frutiger W02';
    src: url('./assets/fonts/4d38d09a-3017-4733-a624-366fb9a71388.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/4200c415-688f-43d6-afb2-059d7aec7a02.woff2') format('woff2'),
      url('./assets/fonts/3c514636-142f-43f9-983f-76110a86b424.woff') format('woff'),
      url('./assets/fonts/f147a146-bb13-4102-a482-bbad38d9fcc0.ttf') format('truetype'),
      url('./assets/fonts/10c3886a-7856-45f7-951d-24230bc1491a.svg#10c3886a-7856-45f7-951d-24230bc1491a') format('svg');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Frutiger W02_i7';
    src: url('./assets/fonts/02b9986e-456b-4558-a8ad-505610712af0.eot?#iefix') format('embedded-opentype');
  }
  @font-face {
    font-family: 'Frutiger W02';
    src: url('./assets/fonts/02b9986e-456b-4558-a8ad-505610712af0.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/f5c4b9b1-3337-41cb-8335-1dd77dc77cc9.woff2') format('woff2'),
      url('./assets/fonts/39967461-d5a8-478e-b0ea-71e3a19e6026.woff') format('woff'),
      url('./assets/fonts/8acd4c9f-64ad-413f-a91b-62cab06e39ea.ttf') format('truetype'),
      url('./assets/fonts/901c0cd0-12ab-4cc1-98fa-03352a525e92.svg#901c0cd0-12ab-4cc1-98fa-03352a525e92') format('svg');
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'Frutiger W01_n2';
    src: url('./assets/fonts/81e3653a-53bd-49d9-a1f8-f924811b6f02.eot?#iefix') format('embedded-opentype');
  }
  @font-face {
    font-family: 'Frutiger W01';
    src: url('./assets/fonts/81e3653a-53bd-49d9-a1f8-f924811b6f02.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/087d8a95-3f25-4bae-a1d1-db9ddffd72f5.woff2') format('woff2'),
      url('./assets/fonts/ab1bcdf7-9570-4cba-a8e3-3e39ddd861db.woff') format('woff'),
      url('./assets/fonts/83c94878-06c8-442c-a7df-d520b0ced00b.ttf') format('truetype'),
      url('./assets/fonts/29f3ff8a-1719-4e25-a757-53ee1a1114a5.svg#29f3ff8a-1719-4e25-a757-53ee1a1114a5') format('svg');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Univers W01_n2';
    src: url('./assets/fonts/a91ff084-fc8b-43f4-b0ea-111254b68524.eot?#iefix') format('embedded-opentype');
  }
  @font-face {
    font-family: 'Univers W01';
    src: url('./assets/fonts/a91ff084-fc8b-43f4-b0ea-111254b68524.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/9fa24f1f-00d8-4d28-910f-78e925eaead6.woff2') format('woff2'),
      url('./assets/fonts/d64e7bbc-e9ea-46ad-982c-6195ae4a3c61.woff') format('woff'),
      url('./assets/fonts/d270a34d-b6b8-4c07-8454-03a6a3db7603.ttf') format('truetype'),
      url('./assets/fonts/b2ea19ff-0237-4d94-a107-eaa12af161ef.svg#b2ea19ff-0237-4d94-a107-eaa12af161ef') format('svg');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Univers W01_n4';
    src: url('./assets/fonts/6510377f-160a-4f66-aec0-2b71f05e9736.eot?#iefix') format('embedded-opentype');
  }
  @font-face {
    font-family: 'Univers W01';
    src: url('./assets/fonts/6510377f-160a-4f66-aec0-2b71f05e9736.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/5c8d59c2-9c85-4a22-88e3-bf3fba01dcd8.woff2') format('woff2'),
      url('./assets/fonts/fc51d03b-0d7d-45df-9415-3c6270c80749.woff') format('woff'),
      url('./assets/fonts/74bdff6d-c5de-42e8-a4d8-11ea14eddb22.ttf') format('truetype'),
      url('./assets/fonts/bd04ae3d-23dc-4975-9967-e21c43e5b59f.svg#bd04ae3d-23dc-4975-9967-e21c43e5b59f') format('svg');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Univers W01_n7';
    src: url('./assets/fonts/38dc895e-c366-4a3e-b212-a84a2d07a329.eot?#iefix') format('embedded-opentype');
  }
  @font-face {
    font-family: 'Univers W01';
    src: url('./assets/fonts/38dc895e-c366-4a3e-b212-a84a2d07a329.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/8a711a0d-3f17-4bfe-887b-6229858332b3.woff2') format('woff2'),
      url('./assets/fonts/05a79c4b-9e9d-46e4-9d4b-610be05371c4.woff') format('woff'),
      url('./assets/fonts/4e00405b-a96d-4972-bdff-2d55e1066c45.ttf') format('truetype'),
      url('./assets/fonts/1e2c0a36-e8bb-4e3b-a4ca-dec00221c06e.svg#1e2c0a36-e8bb-4e3b-a4ca-dec00221c06e') format('svg');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'woodfordbourne';
    src: url('./assets/fonts/woodfordbourne-regular-webfont.woff2') format('woff2'),
      url('./assets/fonts/woodfordbourne-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'woodfordbourne';
    src: url('./assets/fonts/woodfordbourne-bold-webfont.woff2') format('woff2'),
      url('./assets/fonts/woodfordbourne-bold-webfont.woff') format('woff');
    font-style: normal;
    font-weight: 700;
  }
  @font-face {
    font-family: 'woodfordbourne';
    src: url('./assets/fonts/woodfordbourne-thin-webfont.woff2') format('woff2'),
      url('./assets/fonts/woodfordbourne-thin-webfont.woff') format('woff');
    font-style: normal;
    font-weight: 300;
  }
  @font-face {
    font-family: 'FontAwesome';
    src: url('./assets/fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('./assets/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
      url('./assets/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
      url('./assets/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
      url('./assets/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
      url('./assets/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url('./assets/fonts/fa-regular-400.eot');
    src: url('./assets/fonts/fa-regular-400.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/fa-regular-400.woff2') format('woff2'),
      url('./assets/fonts/fa-regular-400.woff') format('woff'),
      url('./assets/fonts/fa-regular-400.ttf') format('truetype'),
      url('./assets/fonts/fa-regular-400.svg#fontawesome') format('svg');
  }

  @font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url('./assets/fonts/fa-solid-900.eot');
    src: url('./assets/fonts/fa-solid-900.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/fa-solid-900.woff2') format('woff2'), url('./assets/fonts/fa-solid-900.woff') format('woff'),
      url('./assets/fonts/fa-solid-900.ttf') format('truetype'),
      url('./assets/fonts/fa-solid-900.svg#fontawesome') format('svg');
  }
`;
